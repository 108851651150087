<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1400px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ product.label }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    v-model.number="qte"
                    :label="'Quantite commandée (' + product.unit + ')'"
                    dense
                    outlined
                    @focus="$event.target.select()"
                    @input="bom_change"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4"></v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="text-bold text-right py-4"
                >
                  <span
                    :key="kb"
                    :class="
                      qte_fais >= qte
                        ? 'green--text text-bold text-right py-4'
                        : 'red--text text-bold text-right py-4'
                    "
                  >
                    {{
                      "Quantité faisable : " +
                      numberWithSpace(qte_fais.toFixed(2)) +
                      " (" +
                      product.unit +
                      ")"
                    }}</span
                  >
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-row>
                    <v-col cols="12" sm="1" md="1">Par Article</v-col>
                    <v-col cols="12" sm="4" md="4" class="text-center">{{
                      "Produit/Article"
                    }}</v-col>
                    <v-col cols="12" sm="1" md="1" class="text-center"
                      >Ratio</v-col
                    >
                    <v-col cols="12" sm="1" md="1" class="text-center"
                      >Dechet (%)</v-col
                    >
                    <v-col cols="12" sm="2" md="2" class="text-center">
                      Stock
                    </v-col>
                    <v-col cols="12" sm="1" md="1" class="text-center">
                      Besoin
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="text-center"
                      >Exces Laize</v-col
                    >
                  </v-row>
                  <v-row v-for="bom in boms" :key="bom.id" dense>
                    <v-col cols="12" sm="1" md="1">
                      <v-checkbox
                        v-model="bom.byarticle"
                        class="ma-2"
                        @change="byarticle_change(bom)"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" v-if="bom.byarticle == 0">
                      <v-autocomplete
                        :items="bom.products"
                        v-model="bom.produit_id"
                        :item-text="'product_label'"
                        :item-value="'produit_id'"
                        :label="bom.caption"
                        outlined
                        dense
                        :key="kp"
                        @change="product_change(bom)"
                      >
                        <template v-slot:item="{ item }">
                          <v-list max-width="700">
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="item.product_label"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-html="'Ref. : ' + item.ref"
                                v-if="item.ref"
                              ></v-list-item-subtitle>

                              <v-list-item-subtitle
                                v-html="
                                  'Stock Dispo : ' +
                                  numberWithSpace(item.stock_dispo_produit) +
                                  ' ' +
                                  (item.unit ? item.unit : '')
                                "
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                :class="
                                  item.stock_dispo_produit >= bom.besoin
                                    ? 'green'
                                    : item.stock_dispo_produit == 0
                                    ? 'red'
                                    : 'orange'
                                "
                                v-html="
                                  'Quantité faisable : ' +
                                  numberWithSpace(qte_fais_int(bom, item)) +
                                  ' ' +
                                  (product.unit ? product.unit : '')
                                "
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" v-if="bom.byarticle == 1">
                      <v-autocomplete
                        :items="bom.products"
                        v-model="bom.article_id"
                        :item-text="'article_label'"
                        :item-value="'id'"
                        :label="bom.caption"
                        outlined
                        dense
                        :key="kp"
                        @change="product_change(bom)"
                      >
                        <template v-slot:item="{ item }">
                          <v-list max-width="700">
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="item.article_label"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-html="'Ref. : ' + item.ref"
                                v-if="item.ref"
                              ></v-list-item-subtitle>

                              <v-list-item-subtitle
                                v-html="
                                  'Stock Dispo : ' +
                                  numberWithSpace(item.stock_dispo) +
                                  ' ' +
                                  (item.unit ? item.unit : '')
                                "
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                :class="
                                  item.stock_dispo >= bom.besoin
                                    ? 'green'
                                    : item.stock_dispo == 0
                                    ? 'red'
                                    : 'orange'
                                "
                                v-html="
                                  'Quantité faisable : ' +
                                  numberWithSpace(qte_fais_int(bom, item)) +
                                  ' ' +
                                  (product.unit ? product.unit : '')
                                "
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-text-field
                        autocomplete="off"
                        type="number"
                        v-model.number="bom.ratio"
                        :label="
                          '(' +
                          (bom.support == 1 ? 'ml' : bom.ratio_unit_name) +
                          ')'
                        "
                        dense
                        outlined
                        @input="bom_change"
                        @focus="$event.target.select()"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-text-field
                        autocomplete="off"
                        type="number"
                        v-model.number="bom.dechet"
                        dense
                        outlined
                        @input="bom_change"
                        @focus="$event.target.select()"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                      class="text-bold text-right py-4"
                    >
                      <span :key="kb">
                        {{
                          numberWithSpace(stock(bom)) + " (" + bom.unit + ")"
                        }}</span
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      sm="1"
                      md="1"
                      :class="
                        bom.besoin >
                        (bom.byarticle == 0
                          ? bom.stock_dispo_produit
                          : bom.stock_dispo)
                          ? 'red--text text-bold text-right py-4'
                          : 'green--text text-bold text-right py-4'
                      "
                    >
                      <span :key="kb">
                        {{
                          numberWithSpace(bom.besoin) + " (" + bom.unit + ")"
                        }}</span
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                      v-if="bom.support == 1 && bom.perte"
                      class="text-bold text-right py-4"
                    >
                      <span :key="kb">
                        {{
                          numberWithSpace(
                            bom.laize ? bom.laize.toFixed(2) : "0.00"
                          ) +
                          bom.laize_unit_name +
                          " (" +
                          bom.perte +
                          "%" +
                          ")"
                        }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
function sortByAttributeValue(attributeId) {
  return function (a, b) {
    // Parse attributs_json strings to JSON objects
    let attributsJsonA = JSON.parse(a.attributs_json);
    let attributsJsonB = JSON.parse(b.attributs_json);

    // Find values of the given attributeId
    let valueA =
      attributsJsonA.find((obj) => obj.attribute_id === attributeId)?.value ||
      0;
    let valueB =
      attributsJsonB.find((obj) => obj.attribute_id === attributeId)?.value ||
      0;

    // Compare values and return result
    return valueA - valueB;
  };
}

import GETPRODUCTBOM from "../graphql/Production/GetProductBom.gql";

export default {
  components: {},
  name: "bomform",
  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
    gammes: Array,
    title: String,
    unit_name: String,
    product: Object,
    units: Array,
    produit_id: Number,
    templates: Array,
    attributes: Array,
  },
  data: () => ({
    option: "1",
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      qte: 1,
    },
    editedItem: {},
    cs: 0,
    kp: 100,
    kb: 200,
    label: "Ratio",
    boms: [],
    recette: [],
    qte: 1,
    qte_fais: 0,
  }),

  computed: {},
  watch: {},

  created() {},
  async mounted() {
    if (this.produit_id) {
      this.qte = this.product.qte;
      // let where = "";
      if (this.product.id > 0)
        if (this.product.boms) {
          // let i = 0;
          this.boms = JSON.parse(this.product.boms);
          // this.boms.forEach((element) => {
          //   let att = element.attributes;
          //   if (i > 0) where += " or";
          //   i++;
          //   where += " (fk_template=" + element.fk_template + " and (";
          //   let j = 0;
          //   att.forEach((attribute) => {
          //     let vc;
          //     let k = this.attributes.findIndex(
          //       (elm) => elm.id == attribute.attribute_id
          //     );
          //     if (k >= 0) {
          //       let at = this.attributes[k];
          //       if (attribute.value || attribute.value_id) {
          //         let v, c;
          //         if (j > 0) where += " and ";
          //         j++;

          //         if (!Array.isArray(attribute.value_id)) {
          //           if (attribute.value) {
          //             v = attribute.value;
          //             c = "value";
          //           }
          //           if (attribute.value_id) {
          //             v = attribute.value_id;
          //             c = "attribute_value_id";
          //           }
          //           vc =
          //             at.hyritage == 1
          //               ? " JSON_CONTAINS(attributs_json, '{\"" +
          //                 c +
          //                 '": "' +
          //                 v +
          //                 "\"}', '$')"
          //               : "CAST(JSON_UNQUOTE(JSON_EXTRACT(attributs_json, '$[*]." +
          //                 c +
          //                 "')) AS DECIMAL(10,2)) >=" +
          //                 v;

          //           where +=
          //             '(JSON_CONTAINS(attributs_json, \'{"attribute_id": "' +
          //             attribute.attribute_id +
          //             "\"}', '$') and " +
          //             vc +
          //             ")";
          //         }

          //         if (attribute.values.length > 0) {
          //           where +=
          //             '(JSON_CONTAINS(attributs_json, \'{"attribute_id": "' +
          //             attribute.attribute_id +
          //             "\"}', '$') and (";
          //           let k = 0;
          //           attribute.values.forEach((elm) => {
          //             if (k > 0) where += " or ";
          //             k++;
          //             where +=
          //               ' JSON_CONTAINS(attributs_json, \'{"attribute_value_id": "' +
          //               elm +
          //               "\"}', '$')";
          //           });
          //           where += "))"; // added missing closing parenthesis
          //         }
          //       }
          //     }
          //   });
          //   where += " ))";
          // });
          let r = await this.requette(GETPRODUCTBOM, {
            product_id: this.produit_id,
          });

          if (r) {
            let index = 0;
            this.boms.forEach((bom) => {
              bom.index = index;
              index++;
              let i = this.templates.findIndex(
                (elm) => elm.id == bom.fk_template
              );
              if (i >= 0) {
                bom.byarticle = 0;
                bom.unit_name = this.templates[i].unit_name;
                bom.fk_unit_ratio = this.templates[i].fk_unit_ratio;
                bom.support = this.templates[i].support;
                let j = this.units.findIndex(
                  (elm) => elm.id == this.templates[i].fk_unit_ratio
                );
                if (j >= 0) {
                  bom.ratio_unit_name = this.units[j].name;
                }
              }
              bom.ratio = bom.ratio ? bom.ratio.toFixed(2) : 0;
              let bom_atts = bom.attributes;
              bom.products = [];
              let v_sort;
              r.getbomproduct
                .filter((elm) => elm.fk_template == bom.fk_template)
                .forEach((product) => {
                  let product_att = JSON.parse(product.attributs_json);

                  let ok = true;
                  for (let index = 0; index < bom_atts.length; index++) {
                    const bom_att = bom_atts[index];

                    let i = product_att.findIndex(
                      (elm) => elm.attribute_id == bom_att.attribute_id
                    );

                    if (i == -1) {
                      ok = false;
                      break;
                    } else {
                      let v, v1;
                      if (bom_att.value) v = bom_att.value;
                      if (bom_att.value_id) v = bom_att.value_id;
                      if (product_att[i].value) v1 = product_att[i].value;
                      if (product_att[i].attribute_value_id)
                        v1 = product_att[i].attribute_value_id;
                      let k = this.attributes.findIndex(
                        (elm) => elm.id == bom_att.attribute_id
                      );

                      if (k >= 0) {
                        if (this.attributes[k].control == 1)
                          v_sort = bom_att.attribute_id;
                        if (
                          this.attributes[k].hyritage == 1 &&
                          parseFloat(v1) != parseFloat(v)
                        ) {
                          ok = false;
                          break;
                        }
                        if (
                          this.attributes[k].control == 1 &&
                          parseFloat(v1) < parseFloat(v)
                        ) {
                          ok = false;
                          break;
                        }
                      }
                    }
                  }
                  if (ok) bom.products.push(product);
                });
              if (bom.products.length > 0) {
                if (v_sort) bom.products.sort(sortByAttributeValue(v_sort));

                let j = bom.products.findIndex(
                  (elm) => elm.stock_dispo_produit >= bom.besoin
                );
                if (j >= 0) {
                  if (bom.byarticle == 0)
                    bom.produit_id = bom.products[j].produit_id;
                  else bom.article_id = bom.products[j].id;
                } else {
                  let k = bom.products.findIndex(
                    (elm) => elm.stock_dispo_produit > 0
                  );
                  if (k >= 0) {
                    if (bom.byarticle == 0)
                      bom.produit_id = bom.products[k].produit_id;
                    else bom.article_id = bom.products[k].id;
                  } else {
                    if (bom.byarticle == 0)
                      bom.produit_id = bom.products[0].produit_id;
                    else bom.article_id = bom.products[0].id;
                  }
                }

                this.product_change(bom);
              }
            });
            this.kp++;
            this.qte_fais = Math.min.apply(
              Math,
              this.boms.map((elm) => elm.qte_fais)
            );
          }
        }
    }
  },

  methods: {
    qte_reserve_tmp(bom) {
      let q = 0;
      for (let index = 0; index < this.boms.length; index++) {
        const element = this.boms[index];
        if (index != bom.index && bom.produit_id == element.produit_id) {
          q = q + parseFloat(element.besoin);
        }
      }
      return q;
    },
    qte_fais_int(bom, item) {
      let s =
        ((bom.byarticle == 0 ? item.stock_dispo_produit : item.stock_dispo) *
          this.qte) /
        bom.besoin;
      return s ? s.toFixed(2) : "0.00";
    },
    stock(bom) {
      let s = bom.byarticle == 0 ? bom.stock_dispo_produit : bom.stock_dispo;
      return s ? s.toFixed(2) : "0.00";
    },
    numberWithSpace: function (x) {
      return x != null
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
    bom_change() {
      this.boms.forEach((bom) => {
        this.product_change(bom);
      });
    },
    byarticle_change(bom) {
      if (bom.products.length > 0)
        if (bom.byarticle == 0) bom.produit_id = bom.products[0].produit_id;
        else bom.article_id = bom.products[0].id;
      this.product_change(bom);
    },
    product_change(bom) {
      let qte = this.qte ? this.qte : 1;
      let dechet = bom.dechet ? bom.dechet / 100 : 0;
      let i;
      if (bom.byarticle == 0)
        i = bom.products.findIndex((elm) => elm.produit_id == bom.produit_id);
      else i = bom.products.findIndex((elm) => elm.id == bom.article_id);

      if (i >= 0) {
        bom.unit = bom.products[i].unit;
        bom.stock_dispo_produit =
          bom.products[i].stock_dispo_produit - this.qte_reserve_tmp(bom);
        bom.stock_dispo = bom.products[i].stock_dispo;
        bom.besoin = null;
        if (bom.products[i].support == 1) {
          //exces de laize
          let att = bom.attributes;
          let laize_p = 0;
          for (let index = 0; index < att.length; index++) {
            const element = att[index];
            let k = this.attributes.findIndex(
              (elm) => elm.id == element.attribute_id
            );
            if (k >= 0) {
              if (this.attributes[k].laize) {
                laize_p = element.value;
                let u = this.units.findIndex(
                  (elm) => elm.id == this.attributes[k].fk_unit
                );
                if (u >= 0) bom.laize_unit_name = this.units[u].name;
              }
            }
          }
          let u = 1;
          let g = 1;
          if (bom.products[i].fk_unit == 8) {
            bom.besoin = (bom.ratio * (1 + dechet) * qte).toFixed(2);
            let product_att = JSON.parse(bom.products[i].attributs_json);
            let v_laize;
            for (let index = 0; index < product_att.length; index++) {
              const element = product_att[index];
              let k = this.attributes.findIndex(
                (elm) => elm.id == element.attribute_id
              );
              if (k >= 0) {
                if (this.attributes[k].laize) {
                  if (element.value) v_laize = element.value;
                }
              }
            }

            if (v_laize - laize_p > 0) {
              bom.laize = v_laize - laize_p;
              bom.perte = (((v_laize - laize_p) / laize_p) * 100).toFixed(2);
            }
          } else {
            let product_att = JSON.parse(bom.products[i].attributs_json);
            //find grammage
            let j = -1; //= product_att.findIndex((elm) => elm.fk_unit_m2 != null);
            let fk_unit_m2;
            let laize = 0;

            let laize_unit;
            let v_laize;

            for (let index = 0; index < product_att.length; index++) {
              const product = product_att[index];
              let k = this.attributes.findIndex(
                (elm) => elm.id == product.attribute_id
              );
              if (k >= 0) {
                if (this.attributes[k].laize) {
                  laize = this.attributes[k].laize;
                  laize_unit = this.attributes[k].fk_unit;
                  if (product.value) v_laize = product.value;
                }
                if (this.attributes[k].fk_unit_m2 != null) {
                  j = index;
                  fk_unit_m2 = this.attributes[k].fk_unit_m2;
                }
              }
            }

            if (j >= 0 && fk_unit_m2) {
              g = product_att[j].value;

              u = this.unit_convert(bom.products[i].fk_unit, fk_unit_m2);
            }
            // let l = product_att.findIndex((elm) => elm.laize == 1);

            let c = 1;
            if (laize == 1) {
              c = this.unit_convert(8, laize_unit);
            }

            if (v_laize - laize_p > 0) bom.laize = v_laize - laize_p;

            bom.besoin = (
              bom.ratio *
              (v_laize / c) *
              (g / u) *
              (1 + dechet) *
              qte
            ).toFixed(2);
            this.kb++;
          }
        } else {
          let j = this.templates.findIndex((elm) => elm.id == bom.fk_template);
          if (j >= 0) {
            bom.unit_name = this.templates[j].unit_name;
            bom.fk_unit_ratio = this.templates[j].fk_unit_ratio;
          }
          let c = 1;
          if (bom.fk_unit_ratio != bom.products[i].fk_unit) {
            c = this.unit_convert(bom.products[i].fk_unit, bom.fk_unit_ratio);
          }
          bom.besoin = ((bom.ratio / c) * (1 + dechet) * qte).toFixed(2);
        }
        bom.qte_fais =
          ((bom.byarticle == 0 ? bom.stock_dispo_produit : bom.stock_dispo) *
            this.qte) /
          bom.besoin;

        this.qte_fais = Math.min.apply(
          Math,
          this.boms.map((elm) => elm.qte_fais)
        );
        this.kb++;
      }
    },
    unit_convert(fk_unit, fk_unit2) {
      let f = 1;
      let i = this.units.findIndex((elm) => elm.id == fk_unit);
      if (i >= 0) {
        let cat_unit = this.units[i].category_id;

        let j = this.units.findIndex((elm) => elm.id == fk_unit2);
        if (j >= 0) {
          let cat_2 = this.units[j].category_id;
          if (cat_unit == cat_2) {
            let factor_unit_product = this.units[i].factor
              ? this.units[i].factor
              : 0;
            let factor_unit_2 = this.units[j].factor ? this.units[j].factor : 1;

            f = factor_unit_product / factor_unit_2;
          }
        }
      }
      return f;
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    customFilter(item, queryText) {
      let textlist = [];
      ["ref", "code", "label"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    close() {
      this.$emit("close", this.items);
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
  },
};
</script>
<style scoped>
.text-field-red >>> .v-text-field__slot input {
  color: red;
}
.text-field-green >>> .v-text-field__slot input {
  color: rgb(77, 233, 15);
}
.text-xs-right {
  display: flex;
  align-items: center;
}
/* span {
  position: relative;
  right: 0;
  top: 100;
  height: 50%;
  display: flex;
  text-align: center;
  vertical-align: middle;
  float: right;
} */
</style>
