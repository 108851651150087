var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"max-width":"1400px","persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.product.label))])]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"autocomplete":"off","type":"number","label":'Quantite commandée (' + _vm.product.unit + ')',"dense":"","outlined":"","hide-spin-buttons":""},on:{"focus":function($event){return $event.target.select()},"input":_vm.bom_change},model:{value:(_vm.qte),callback:function ($$v) {_vm.qte=_vm._n($$v)},expression:"qte"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}}),_c('v-col',{staticClass:"text-bold text-right py-4",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('span',{key:_vm.kb,class:_vm.qte_fais >= _vm.qte
                      ? 'green--text text-bold text-right py-4'
                      : 'red--text text-bold text-right py-4'},[_vm._v(" "+_vm._s("Quantité faisable : " + _vm.numberWithSpace(_vm.qte_fais.toFixed(2)) + " (" + _vm.product.unit + ")"))])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_vm._v("Par Article")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"4","md":"4"}},[_vm._v(_vm._s("Produit/Article"))]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"1","md":"1"}},[_vm._v("Ratio")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"1","md":"1"}},[_vm._v("Dechet (%)")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"2","md":"2"}},[_vm._v(" Stock ")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"1","md":"1"}},[_vm._v(" Besoin ")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"2","md":"2"}},[_vm._v("Exces Laize")])],1),_vm._l((_vm.boms),function(bom){return _c('v-row',{key:bom.id,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-checkbox',{staticClass:"ma-2",on:{"change":function($event){return _vm.byarticle_change(bom)}},model:{value:(bom.byarticle),callback:function ($$v) {_vm.$set(bom, "byarticle", $$v)},expression:"bom.byarticle"}})],1),(bom.byarticle == 0)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{key:_vm.kp,attrs:{"items":bom.products,"item-text":'product_label',"item-value":'produit_id',"label":bom.caption,"outlined":"","dense":""},on:{"change":function($event){return _vm.product_change(bom)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('v-list',{attrs:{"max-width":"700"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.product_label)}}),(item.ref)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s('Ref. : ' + item.ref)}}):_vm._e(),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                                'Stock Dispo : ' +
                                _vm.numberWithSpace(item.stock_dispo_produit) +
                                ' ' +
                                (item.unit ? item.unit : '')
                              )}}),_c('v-list-item-subtitle',{class:item.stock_dispo_produit >= bom.besoin
                                  ? 'green'
                                  : item.stock_dispo_produit == 0
                                  ? 'red'
                                  : 'orange',domProps:{"innerHTML":_vm._s(
                                'Quantité faisable : ' +
                                _vm.numberWithSpace(_vm.qte_fais_int(bom, item)) +
                                ' ' +
                                (_vm.product.unit ? _vm.product.unit : '')
                              )}})],1)],1)]}}],null,true),model:{value:(bom.produit_id),callback:function ($$v) {_vm.$set(bom, "produit_id", $$v)},expression:"bom.produit_id"}})],1):_vm._e(),(bom.byarticle == 1)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{key:_vm.kp,attrs:{"items":bom.products,"item-text":'article_label',"item-value":'id',"label":bom.caption,"outlined":"","dense":""},on:{"change":function($event){return _vm.product_change(bom)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                              var item = ref.item;
return [_c('v-list',{attrs:{"max-width":"700"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.article_label)}}),(item.ref)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s('Ref. : ' + item.ref)}}):_vm._e(),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                                'Stock Dispo : ' +
                                _vm.numberWithSpace(item.stock_dispo) +
                                ' ' +
                                (item.unit ? item.unit : '')
                              )}}),_c('v-list-item-subtitle',{class:item.stock_dispo >= bom.besoin
                                  ? 'green'
                                  : item.stock_dispo == 0
                                  ? 'red'
                                  : 'orange',domProps:{"innerHTML":_vm._s(
                                'Quantité faisable : ' +
                                _vm.numberWithSpace(_vm.qte_fais_int(bom, item)) +
                                ' ' +
                                (_vm.product.unit ? _vm.product.unit : '')
                              )}})],1)],1)]}}],null,true),model:{value:(bom.article_id),callback:function ($$v) {_vm.$set(bom, "article_id", $$v)},expression:"bom.article_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-text-field',{attrs:{"autocomplete":"off","type":"number","label":'(' +
                        (bom.support == 1 ? 'ml' : bom.ratio_unit_name) +
                        ')',"dense":"","outlined":"","hide-spin-buttons":""},on:{"input":_vm.bom_change,"focus":function($event){return $event.target.select()}},model:{value:(bom.ratio),callback:function ($$v) {_vm.$set(bom, "ratio", _vm._n($$v))},expression:"bom.ratio"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-text-field',{attrs:{"autocomplete":"off","type":"number","dense":"","outlined":"","hide-spin-buttons":""},on:{"input":_vm.bom_change,"focus":function($event){return $event.target.select()}},model:{value:(bom.dechet),callback:function ($$v) {_vm.$set(bom, "dechet", _vm._n($$v))},expression:"bom.dechet"}})],1),_c('v-col',{staticClass:"text-bold text-right py-4",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('span',{key:_vm.kb},[_vm._v(" "+_vm._s(_vm.numberWithSpace(_vm.stock(bom)) + " (" + bom.unit + ")"))])]),_c('v-col',{class:bom.besoin >
                      (bom.byarticle == 0
                        ? bom.stock_dispo_produit
                        : bom.stock_dispo)
                        ? 'red--text text-bold text-right py-4'
                        : 'green--text text-bold text-right py-4',attrs:{"cols":"12","sm":"1","md":"1"}},[_c('span',{key:_vm.kb},[_vm._v(" "+_vm._s(_vm.numberWithSpace(bom.besoin) + " (" + bom.unit + ")"))])]),(bom.support == 1 && bom.perte)?_c('v-col',{staticClass:"text-bold text-right py-4",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('span',{key:_vm.kb},[_vm._v(" "+_vm._s(_vm.numberWithSpace( bom.laize ? bom.laize.toFixed(2) : "0.00" ) + bom.laize_unit_name + " (" + bom.perte + "%" + ")"))])]):_vm._e()],1)})],2)],1)],1)],1),_c('v-card-actions',[_c('v-container',{attrs:{"fill-height":""}},[(_vm.progress)?_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":30,"width":7,"color":"purple"}})],1):_vm._e()],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" Fermer ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }